import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/7V5Zs0xegrY">
        <SEO title="Christmas Eve 2023 - Searching for the Perfect Christmas" />
    </Layout>
)

export default SermonPost
